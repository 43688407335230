/* Import Google Font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

:root {
	font-family: "Poppins", sans-serif;
}

:root {
	--primary-color: #003c58;
	--secondary-color: #ffffff;
}

.App {
	font-family: "Arial", sans-serif;
}

.App-logo {
	height: 50px;
}

.App-header {
	background-color: var(--primary-color);
	color: var(--secondary-color);
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.App-hero {
	text-align: center;
	padding: 2rem;
	background-color: var(--secondary-color);
	color: var(--primary-color);
}

.App-hero-button,
.App-cta-button {
	background-color: var(--primary-color);
	color: var(--secondary-color);
	border: none;
	padding: 10px 20px;
	cursor: pointer;
	margin-top: 1rem;
	font-size: 1rem;
	transition: background-color 0.3s, color 0.3s;
}

.App-hero-button:hover,
.App-cta-button:hover {
	background-color: var(--secondary-color);
	color: var(--primary-color);
	border: 1px solid var(--primary-color);
}

.App-features {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 2rem;
	padding: 2rem;
	background-color: var(--primary-color);
	color: var(--secondary-color);
}

.feature-item {
	width: 100%;
	max-width: 300px;
	padding: 1rem;
	background-color: var(--secondary-color);
	color: var(--primary-color);
	text-align: center;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
	overflow: hidden;
}

.feature-item:hover {
	transform: translateY(-5px);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-image {
	max-width: 100%;
	height: auto;
	border-radius: 5px;
}

.App-footer {
	background-color: var(--primary-color);
	color: var(--secondary-color);
	padding: 1rem;
	text-align: center;
	border-top: 5px solid var(--secondary-color);
}

/* Common section styles */
.section {
	padding: 4rem 2rem;
	text-align: center;
}
.App-empowerment {
	background-color: #f9f9f9; /* Light background to differentiate the section */
	text-align: center; /* Keep headings centered */
	padding: 4rem 1rem; /* Add more padding for spacing */
}

.App-empowerment-content {
	max-width: 800px; /* Increase the content width */
	margin: auto; /* Center the content block */
	padding: 2rem; /* Add padding inside the content for spacing */
	text-align: left; /* Align text to the left for better readability */
}

.App-empowerment h2,
.App-empowerment h3 {
	font-weight: bold; /* Make headings stand out */
	margin-top: 2rem; /* Add space above headings */
}

.App-empowerment p,
.App-empowerment ul {
	text-align: justify; /* Justify text for clean edges */
	line-height: 1.6; /* Improve line spacing for readability */
	margin-bottom: 1rem; /* Add space below paragraphs */
}

/* Video Section */
/* Give a good pleasing full header background */
.App-video {
	padding: 2rem;
	text-align: center;
	background-color: #f9f9f9;
}

/* Container for the video iframe */
.App-video-container {
	position: relative;
	width: 100%;

	height: 600px; /* Set a fixed height for the video section */
}

.App-video-iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: 600px;
}

.App-video iframe {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
	.App-video {
		height: 600px;
	}

	.App-video-container {
		height: 600px;
	}

	.App-empowerment-content {
		padding: 1rem; /* Reduce padding on smaller screens */
	}
	.feature-item {
		margin-bottom: 1rem;
	}

	.App-hero-button,
	.App-cta-button {
		width: 80%;
	}

	.App-video-iframe {
		height: 600px;

		width: 100%;
	}
	.App-features {
		flex-direction: column;
		align-items: center;
	}

	.feature-item {
		width: 90%;
		margin: 0 auto;
	}
}

.App-hero-button,
.App-cta-button {
	padding: 12px 24px;
	border-radius: 8px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease;
}

.App-hero-button:hover,
.App-cta-button:hover {
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
	transform: translateY(-2px);
}
